export const PASSWORD_DEFAULTS = { MAX_LENGTH: 64, MIN_LENGTH: 9 };
export const ORIGIN_IS_SSO_EVENT_LINK = "sso-trigger";
export const ORIGIN_IS_SSO_EVENT_LINK_VALUE = "1";

export enum PROVIDERS {
  EDC = "events.com",
  FACEBOOK = "facebook",
  GOOGLE = "google",
  LINKEDIN = "linkedin",
  MICROSOFT = "microsoft",
}

export const SSO_TOKEN_KEY = "sso-token";
export const SSO_ERROR_KEY = "sso-error";
export const SSO_ERROR_EMAIL = "sso-error-email";
export const WAIT_FOR_KEYS_IN_LOCAL_STORAGE_INTERVAL = 500;

export const LINKEDIN_POPUP_BROWSING_CONTEXT = "linkedinAuth";

export const MAGIC_LINK_EMAIL_KEY = "magic-link-login-email";

export const SOCIAL_PROVIDERS = [
  PROVIDERS.EDC,
  PROVIDERS.MICROSOFT,
  PROVIDERS.GOOGLE,
  PROVIDERS.FACEBOOK,
  PROVIDERS.LINKEDIN,
];

export const SOCIAL_PROVIDERS_SSO_PATHS = SOCIAL_PROVIDERS.map(
  (providerName) => `/${providerName}/sso`,
);

export const ANONYMOUS_USER_EMAIL_DOMAIN = "@remo1.co";

export const forbiddenEmailDomainsForTrial = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "msn.com",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "comcast.net",
  "yahoo.co",
  "live.com",
  "rediffmail.com",
  "free.fr",
  "gmx.de",
  "web.de",
  "yandex.ru",
  "ymail.com",
  "libero.it",
  "outlook.com",
  "uol.com.br",
  "bol.com.br",
  "mail.ru",
  "cox.net",
  "hotmail.it",
  "sbcglobal.net",
  "sfr.fr",
  "live.fr",
  "verizon.net",
  "live.co.uk",
  "googlemail.com",
  "yahoo.es",
  "ig.com.br",
  "live.nl",
  "bigpond.com",
  "terra.com.br",
  "yahoo.it",
  "neuf.fr",
  "yahoo.de",
  "alice.it",
  "rocketmail.com",
  "att.net",
  "laposte.net",
  "facebook.com",
  "bellsouth.net",
  "yahoo.in",
  "hotmail.es",
  "charter.net",
  "yahoo.ca",
  "rambler.ru",
  "hotmail.de",
  "tiscali.it",
  "shaw.ca",
  "sky.com",
  "earthlink.net",
  "optonline.net",
  "freenet.de",
  "t-online.de",
  "aliceadsl.fr",
  "virgilio.it",
  "home.nl",
  "qq.com",
  "telenet.be",
  "me.com",
  "tiscali.co.uk",
  "voila.fr",
  "gmx.net",
  "mail.com",
  "planet.nl",
  "hey.com",
  "tin.it",
  "live.it",
  "ntlworld.com",
  "arcor.de",
  "frontiernet.net",
  "hetnet.nl",
  "live.com.au",
  "zonnet.nl",
  "club-internet.fr",
  "juno.com",
  "optusnet.com.au",
  "blueyonder.co.uk",
  "bluewin.ch",
  "skynet.be",
  "sympatico.ca",
  "windstream.net",
  "mac.com",
  "centurytel.net",
  "chello.nl",
  "live.ca",
  "aim.com",
  "bigpond.net.au",
  "mailinator.com",
];

export const trialFirstNameKey = "trialFirstNameTemp";
export const trialLastNameKey = "trialLastNameTemp";
export const trialPhoneKey = "trialPhoneTemp";
