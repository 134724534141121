export enum EventFeatures {
  TEST_FEATURE = "test-feature",
  USERPILOT = "userpilot",
  VIDEO_ANNOUNCEMENT = "video-announcement",
  VIDEO_OPTIMIZATION = "video-optimization",
}

export enum UserFeatures {
  MULTIPLE_EVENT_DIRECTORIES = "multiple-event-directories",
  TEST_FEATURE = "test-feature",
}

export enum CompanyFeatures {
  MAX_FLOORS_INCREASE = "max-floors-increase",
  NETWORKING_RECOMMENDATIONS = "networking-recommendations",
  TEST_FEATURE = "test-feature",
  TICKETING = "ticketing-host-functionality",
}
